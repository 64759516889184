const product_model = {
    type:"",
    nombre:"",
    tamano:"",
    color:"",
    material:"",
    descripcion:"",
    modelo:0,
    fabricante:"",
    precio: 0,
    stock: true,
    descuento:0,
    img:""
};

export default product_model;